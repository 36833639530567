<template>
  <div class="min-h-screen w-full max-width-algo">
    <div class="xl:px-32 w-full px-0 py-12">
      <div class="flex flex-row md:px-8 px-4 md:pt-4 pt-1 md:pb-4 pb-6">
        <div class="font-semibold lg:text-headline-4 text-headline-6">
          Today's Articles
        </div>
      </div>
      <div class="flex flex-row flex-wrap md:px-5 px-1">
        <small class="mt-10 block" v-if="!fetching && GET_ARTICLES.length === 0"> No available article for today.</small>
      </div>
      <div class="flex flex-row flex-wrap md:px-5 px-1">
        <ArticleCardLoading
          v-show="fetching"
          body-class="px-3 md:w-1/3 w-full  lg:h-40 h-20"
          v-for="index in 3"
          v-bind:key="'loader' + index"
        />
        <ArticleCard
          class="px-3"
          v-show="!fetching"
          v-for="(item, index) in GET_ARTICLES"
          :key="fetchOption.query_value + index"
          :image-src="getImage(item.thumbnail)"
          :slug="slugFromTitle(item.article_title)"
          :title="item.article_title"
          :category="item.category.article_category_name"
          :published-date="moment(item.published_at).format('DD/MM/YYYY')"
          :bookmarked="item.saved_articles.length > 0"
          :author="item.writter"
          @state-toggle="(newState) => { changeArticleSaveState(item) }"
          responsive
        />

      </div>
    </div>
  </div>
</template>

<script>
import ArticleCard from '@/components/Articles/ArticleCard.vue'
import ArticleCardLoading from '@/components/Articles/ArticleCardLoading.vue'
import { createNamespacedHelpers } from 'vuex'

const article = createNamespacedHelpers('article')

export default {
  components: {
    ArticleCard,
    ArticleCardLoading
  },
  data () {
    return {
      fetching: true,
      categoryName: '',
      fetchOption: {
        limit: 12,
        page: 1,
        query_tag: 'time',
        query_value: 'today',
        filter: {
          target: 'id',
          sort: 'desc'
        }
      },
      author: {
        name: 'Rama Krushna',
        username: 'ramkrush',
        avatar: require('@/assets/img/author-avatar-small-1.png')
      },
      images: [
        require('@/assets/img/article-header-preview-1.jpg'),
        require('@/assets/img/article-header-preview-2.jpg'),
        require('@/assets/img/article-header-preview-3.jpg'),
        require('@/assets/img/article-header-preview-4.jpg'),
        require('@/assets/img/article-header-preview-5.jpg'),
        require('@/assets/img/article-header-preview-6.jpg')
      ]
    }
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.initialize()
      }
    }
  },
  computed: {
    ...article.mapGetters(['GET_ARTICLES', 'ARTICLE_READY'])
  },
  methods: {
    ...article.mapActions(['getArticles', 'toggleSaveArticle']),
    getRandomImage () {
      return this.images[Math.floor(Math.random() * this.images.length)]
    },
    changeArticleSaveState (article) {
      this.toggleSaveArticle({ data: { article_id: article.id } }).then(result => {
        if (result.data.id !== undefined) {
          article.saved_articles.push(result.data)
        } else {
          article.saved_articles = []
        }
      })
    },
    getImage (path) {
      // var newPath = path.replace('public/', '')
      // console.log('image url: ' + process.env.VUE_APP_IMAGE_URL)
      return process.env.VUE_APP_IMAGE_URL + '/' + path
    },
    slugFromTitle (title) {
      return title.replace(/ /g, '-')
    },
    initialize () {
      this.fetching = true
      // this.fetchOption.query_value = this.$route.params.slug
      // this.categoryName = this.$route.params.slug.replace(/-/g, ' ')
      const title = 'Algovision today'
      document.title = title
      this.setMetaTag(title, 'Read trending stock, get insight about today stock')
      this.$gtag.screenview({
        app_name: 'Algovision_WebApp',
        screen_name: 'Today'
      })
      this.$gtag.pageview(this.$route.path)
      this.getArticles({ data: this.fetchOption }).then(() => { this.fetching = false })
    }
  },
  created () {
    this.initialize()
  }
}
</script>
